import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    transferIndex: 0,
    transferSymbol: '',
    contractAddress: '',
    contractPools: {},
    search: null,
    lang: ''
  },
  mutations: {
    UPDATE_TF_ADDRESS (state, value) {
      state.contractAddress = value
    },
    UPDATE_TF_INDEX (state, value) {
      state.transferIndex = value
    },
    UPDATE_TF_SYMBOL (state, value) {
      state.transferSymbol = value
    },
    UPDATE_TF_POOLS (state, poolDetail) {
      state.contractPools[poolDetail['address'] + poolDetail['token']] = poolDetail['contract']
    },
    UPDATE_SEARCH (state, value) {
      state.search = value
    }
  },
  actions: {
  },
  modules: {
  }
})
