<template>
  <div class="help" style="height: 100vh; min-height: 800px;">
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-lg="6" vs-sm="8" vs-xs="10" style=" padding-bottom: 5rem; padding-top: 5rem;">
        
        <div class="con-box" style="margin-top: 5rem;">
          <div class="box">
            <h2>
              {{ $t("Is it safe to use this website to deploy ERC20 contracts?") }}
            </h2> 
            <p>{{ $t("Absolutely safe, because the contract source code is open source.") }}</p> 
          </div>
        </div>

        <div class="con-box" style="margin-top: 5rem;">
          <div class="box">
            <h2>
              {{ $t("Who owns the ERC20 tokens I issued?") }}
            </h2> 
            <p>{{ $t("The address where you deploy the contract will hold all the ERC20 tokens issued by you.") }}</p> 
          </div>
        </div>

        <div class="con-box" style="margin-top: 5rem;">
          <div class="box">
            <h2>
              {{ $t("Do I need to pay extra for using website services?") }}
            </h2> 
            <p>{{ $t("No, you only need to pay for the deployment contract.") }}</p> 
          </div>
        </div>

      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: 'Token',
  data() {
    return {
      contractTotal: '',
      contractDecimals: '',
      contractName: '',
      contractSymbol: '',
      colorx:'#c72a75',
      colorx2:'#5252e8'
    };
  },
  props: {
    msg: String
  },
  methods: {
    deployContract: function () {
      console.log(this.$data.contractTotal.length > 0 && this.$data.contractDecimals.length > 0 && this.$data.contractName.length > 0 && this.$data.contractSymbol.length > 0)
    }
  },
  computed: {
    contractDeployable: function () {
      return this.$data.contractTotal.length > 0 && this.$data.contractDecimals.length > 0 && this.$data.contractName.length > 0 && this.$data.contractSymbol.length > 0
    }
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
.field-title {
  margin-bottom: 1.5rem;
}
</style>
