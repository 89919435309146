<template>
  <div class="home">
    <div class="container">
      <b-navbar style="box-shadow: none">
        <template #brand>
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img
              :src="require('@/assets/logo.png')"
              height="30"
              alt=""
              loading="lazy"
            />
          </b-navbar-item>
        </template>
        <template #start>
          <b-navbar-item tag="router-link" :to="{ path: '/' }" href="#">{{
            $t("Home")
          }}</b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/about' }" href="#">{{
            $t("About")
          }}</b-navbar-item>
          <b-navbar-item href="https://doc.tokenhelper.org">{{
            $t("Document")
          }}</b-navbar-item>
        </template>

        <template #end>
          <b-navbar-dropdown label="Language">
            <b-navbar-item
              href="#"
              v-for="lang in langs"
              :key="lang['abbr']"
              v-on:click="changeLanguage(lang)"
              >{{ lang["locale"] }}</b-navbar-item
            >
          </b-navbar-dropdown>
        </template>
      </b-navbar>
    </div>

    <router-view></router-view>

    <footer class="footer" style="padding-bottom: 3.5rem; color: #4a4a4a">
      <div class="content has-text-centered">
        <div class="container" style="padding-bottom: 1rem">
          <div class="columns" style="text-align: start">
            <div class="column is-3">
              <p class="bd-footer-link-title" style="padding: 4rem">
                <strong>{{
                  $t("Help everyone soar in a decentralized world")
                }}</strong>
              </p>
            </div>

            <div class="column is-3">
              <p class="bd-footer-link-title">
                <strong><a>Contact</a></strong>
              </p>

              <p class="bd-footer-link">
                <a href="https://t.me/TokenHelper" >
                  <font-awesome-icon :icon="['fab', 'telegram']" /> TokenHelper
                </a>
              </p>

              <p class="bd-footer-link">
                <a href="">  
                  <font-awesome-icon :icon="['fab', 'weixin']" /> nwihbf
                </a>
              </p>

            </div>

            <div class="column is-3">
              <p class="bd-footer-link-title">
                <strong><a>Terms</a></strong>
              </p>

              <p class="bd-footer-link">
                <a
                  href="/2021/01/23/the-block-probably-bulmas-most-important-feature/"
                >
                  Privacy
                </a>
              </p>
            </div>

            <div class="column is-3">
              <p class="bd-footer-link-title">
                <strong><a>Other</a></strong>
              </p>

              <p class="bd-footer-link">
                <a target="_blank" href="https://bitcoin.org/"> Bitcoin </a>
              </p>

              <p class="bd-footer-link">
                <a target="_blank" href="https://ethereum.org/"> Ethereum </a>
              </p>

              <p class="bd-footer-link">
                <a target="_blank" href="https://solana.com/"> Solana </a>
              </p>
            </div>
          </div>
        </div>
        <hr/>
        <p style="margin-top: 3rem">
          Copyright <strong>©</strong> 2021 TokenHelper All Rights Reserved
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faUserSecret,
  faEnvelope,
  faLanguage,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFontAwesome,
  faTelegram,
  faGoogle,
  faQq,
  faWeixin,
} from "@fortawesome/free-brands-svg-icons";

library.add(faSpinner);
library.add(faUserSecret);
library.add(faTelegram);
library.add(faGoogle);
library.add(faFontAwesome);
library.add(faEnvelope);
library.add(faLanguage);
library.add(faBars);
library.add(faQq);
library.add(faWeixin);

export default {
  name: "Home",
  data() {
    return {
      contractTotal: "",
      contractDecimals: "",
      contractName: "",
      contractSymbol: "",
      colorx: "#c72a75",
      colorx2: "#5252e8",
      langs: [
        { lang: "English", abbr: "en", locale: "English" },
        { lang: "Russian", abbr: "ru", locale: "русский" },
        { lang: "Spanish", abbr: "es", locale: "Español" },
        { lang: "German", abbr: "de", locale: "Deutsch" },
        { lang: "Turkish", abbr: "tr", locale: "Türk" },
        { lang: "Persian", abbr: "fa", locale: "فارسی" },
        { lang: "French", abbr: "fr", locale: "français" },
        { lang: "Japanese", abbr: "ja", locale: "日本" },
        { lang: "Portuguese", abbr: "pt", locale: "português" },
        { lang: "Chinese", abbr: "zh-CN", locale: "简体中文" },
        { lang: "Chinese", abbr: "zh-TW", locale: "繁體中文" },
        { lang: "Vietnamese", abbr: "vi", locale: "Tiếng Việt" },
        { lang: "Italian", abbr: "it", locale: "Italia" },
        { lang: "Arabic", abbr: "ar", locale: "عرب" },
        { lang: "Polish", abbr: "pl", locale: "Polskie" },
        { lang: "Greek", abbr: "el", locale: "Ελληνικά" },
        { lang: "Dutch", abbr: "nl", locale: "Nederlands" },
        { lang: "Indonesian", abbr: "id", locale: "bahasa Indonesia" },
        { lang: "Korean", abbr: "ko", locale: "한국인" },
        { lang: "Lithuanian", abbr: "lt", locale: "Lietuvių" },
      ],
    };
  },
  props: {
    msg: String,
  },
  created: function () {
    this.$root.$i18n.locale = navigator.language;
  },
  methods: {
    changeLanguage: function (lang) {
      this.$root.$i18n.locale = lang["abbr"];
    },
  },
  computed: {},
};
</script>

<style scoped>
footer a {
  color: #4a4a4a;
}
</style>
