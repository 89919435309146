import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home'
import Token from '../components/Token'
import Privacy from '../components/Privacy'
import Terms from '../components/Terms'
import Help from '../components/Help'
import About from '../components/About'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        { 
            path: '/', 
            component: Home,
            children: [
                {
                  path: '/',
                  component: Token
                },
                {
                  path: '/privacy',
                  component: Privacy
                },
                {
                  path: '/terms',
                  component: Terms
                },
                {
                  path: '/help',
                  component: Help
                },
                {
                  path: '/about',
                  component: About
                }
              ]
        }
    ]
})
