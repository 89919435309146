<template>
  <div class="about" style="height: 100vh; min-height: 800px;">
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="4" vs-lg="4" vs-sm="6" vs-xs="10" style=" padding-bottom: 5rem; padding-top: 5rem;">
        
        <div class="con-box" id="introduction">
          <div class="box">
            <h2 style="text-align: center">
              {{ $t("About Us") }}
            </h2> 
            <p>{{ $t("With the popularization of blockchain technology, more and more people have joined the ranks of the blockchain, but the blockchain may have some thresholds, but this will not hinder those who have dreams, we will do our best Help everyone who is about to enter the blockchain, thank you for a long way.") }}</p> 
          </div>
        </div>

      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: 'Token',
  data() {
    return {
      contractTotal: '',
      contractDecimals: '',
      contractName: '',
      contractSymbol: '',
      colorx:'#c72a75',
      colorx2:'#5252e8'
    };
  },
  props: {
    msg: String
  },
  methods: {
    deployContract: function () {
      console.log(this.$data.contractTotal.length > 0 && this.$data.contractDecimals.length > 0 && this.$data.contractName.length > 0 && this.$data.contractSymbol.length > 0)
    }
  },
  computed: {
    contractDeployable: function () {
      return this.$data.contractTotal.length > 0 && this.$data.contractDecimals.length > 0 && this.$data.contractName.length > 0 && this.$data.contractSymbol.length > 0
    }
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
.field-title {
  margin-bottom: 1.5rem;
}
</style>
