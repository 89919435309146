<template>
  <div>
    <div
      class="token"
      style="height: 80vh; min-height: 800px; padding: 8rem 0 8rem"
    >
      <h1 style="text-align: center; margin: 2rem; font-size: 3rem">
        {{ $t("Easily create ERC20 tokens") }}
      </h1>

      <div class="columns is-centered" style="margin-left: 0; margin-right: 0">
        <div
          class="
            column
            is-full-mobile
            is-two-thirds-tablet
            is-half-desktop
            is-two-fifths-widescreen
            is-one-third-fullhd
          "
        >
          <div class="columns">
            <div class="column is-three-quarter">
              <b-field :label="$t('Total Supply')">
                <b-input
                  value="johnsilver"
                  type="number"
                  v-model="contractTotal"
                ></b-input>
              </b-field>
            </div>
            <span
              style="
                font-size: 3rem;
                margin-top: 0.5rem;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
              "
              >.</span
            >
            <div class="column is-one-quarter">
              <b-field :label="$t('Decimals')">
                <b-input
                  value="johnsilver"
                  type="number"
                  v-model="contractDecimals"
                ></b-input>
              </b-field>
            </div>
          </div>
          <b-field :label="$t('Token name. e.g. “MyToken”')">
            <b-input value="" v-model="contractName"></b-input>
          </b-field>
          <b-field :label="$t('Token symbol. e.g. “HIX”.')">
            <b-input value="" v-model="contractSymbol"></b-input>
          </b-field>
          <div style="margin-top: 2rem">
            <b-button
              class="full-width"
              size="is-large"
              :disabled="contractDeployable == false"
              v-on:click="deployContract()"
              type="is-primary"
              >{{ $t("Contract Deployment") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <section class="section ls-features-cards" style="padding: 10rem 1.5rem">
      <div class="container">
        <div class="columns">
          <div class="column is-8-desktop is-offset-2-desktop">
            <div class="ls-section-content has-text-centered">
              <h3 class="title has-text-dark">{{ $t("Your trusted partner") }}</h3>
              <p class="content has-text-grey is-size-5">
                {{ $t("Solve all your confusion about issuing ERC20 tokens and help you realize your dreams") }}
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <article class="card ls-feature-card">
              <div class="card-content">
                <figure class="image is-159x159">
                  <img
                    src="@/assets/opensource.png"
                    title="LandSpeed UI"
                    width="145"
                    height="145"
                  />
                </figure>
                <div class="media-content has-text-centered">
                  <h3 class="title is-5 has-text-weight-bold">
                    <a href="/" class="has-text-dark" title="">{{ $t("Free and open source") }}</a>
                  </h3>
                  <p class="is-size-6">
                    {{ $t("The ERC20 tokens we issue are all open source, no loopholes, no backdoors") }}
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div class="column is-one-third">
            <article class="card ls-feature-card">
              <div class="card-content">
                <figure class="image is-159x159">
                  <img
                    src="@/assets/secure.png"
                    title="LandSpeed UX"
                    width="145"
                    height="145"
                  />
                </figure>
                <div class="media-content has-text-centered">
                  <h3 class="title is-5 has-text-weight-bold">
                    <a href="/" class="has-text-dark" title="">{{ $t("contract security") }}</a>
                  </h3>
                  <p class="is-size-6">
                    {{ $t("The contract has a built-in SafeMath secure computing library, rejecting all attacks") }}
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div class="column is-one-third">
            <article class="card ls-feature-card">
              <div class="card-content">
                <figure class="image is-159x159">
                  <img
                    src="@/assets/certificate.png"
                    title="LandSpeed UI"
                    style="width: 500px"
                  />
                </figure>
                <div class="media-content has-text-centered">
                  <h3 class="title is-5 has-text-weight-bold">
                    <a href="/" class="has-text-dark" title="">{{ $t("Get a certificate") }}</a>
                  </h3>
                  <p class="is-size-6">
                    {{ $t("Don't worry about getting the contract certificate anymore, get it easily") }}
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>

    <section class="section ls-background-light" style="padding: 10rem 0">
      <div class="container">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="ls-section-content has-text-centered">
              <h3 class="title has-text-dark">{{ $t("Support multiple main chains") }}</h3>
              <p class="content has-text-grey has-text-weight-light is-size-5">
                {{ $t("You can use it in a browser with the Metamask plugin, or in a mobile wallet like Trust Wallet, anyway we support it all") }}
              </p>
            </div>
          </div>
        </div>
        <div class="glide">
          <div class="columns">
            <div class="column">
              <img src="@/assets/eth.png" style="width: 350px;margin: 0 auto;display: block;" alt="Ehereum" />
            </div>
            <div class="column">
              <img
                src="@/assets/bsc.png"
                style="width: 250px;margin: 0 auto;display: block;"
                alt="Binance Smart Chain"
              />
            </div>
            <div class="column">
              <img
                src="@/assets/heco.jpeg"
                style="width: 350px;margin: 0 auto;display: block;"
                alt="Heco Chain"
              />
            </div>
            <div class="column">
              <img
                src="@/assets/okex.png"
                style="width: 350px;margin: 0 auto;display: block;"
                alt="Okex Chain"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { abi, bytecode, wallet } from "../assets/contract";
import Swal from "sweetalert2";

const Web3 = require("web3");

export default {
  name: "Token",
  data() {
    return {
      contractTotal: 21000000,
      contractDecimals: 18,
      contractName: "",
      contractSymbol: "",
      defaultDeployFee: 0.09853394 * Math.pow(10, 18),
      defaultGasPrice: 10 * Math.pow(10, 9),
      deployedTransaction: null,
      deployedContract: null,
      loadingComponent: null,
      loadingText: this.$t("sending transaction..."),
      showDialog: true,
      colorx: "#c72a75",
      colorx2: "#5252e8",
    };
  },
  created: function () {},
  mounted: function () {
    //在和以太坊兼容的浏览器中使用 web3.js 时，当前环境的原生 provider 会被浏览器设置
    //例如安装了MetaMask，它在浏览器中注入了window.ethereum下的提供者对象，我们就可以通过window.ethereum来初始化web3对象
    var web3Provider;
    if (window.ethereum) {
      web3Provider = window.ethereum;
    } else if (window.web3) {
      // 老版 MetaMask Legacy dapp browsers...
      web3Provider = window.web3.currentProvider;
    } else {
      console.log("error! not install eth client");
    }
    this.web3 = new Web3(web3Provider);
    this.web3.eth.getGasPrice().then((price) => {
      this.$data.defaultGasPrice = price;
    });
  },
  methods: {
    deployContract: async function () {
      let deploy_contract = new this.web3.eth.Contract(JSON.parse(abi));
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log(accounts);
      deploy_contract
        .deploy({
          data: bytecode,
          arguments: [
            this.$data.contractName,
            this.$data.contractSymbol,
            this.$data.contractDecimals,
            this.$data.contractTotal,
            wallet,
          ],
        })
        .send(
          {
            from: accounts[0],
            value: this.$data.defaultDeployFee,
          },
          (err, transactionHash) => {
            if (err != null) {
              Swal.hideLoading();
              if (err.code == 4001) {
                Swal.fire({
                  icon: "error",
                  title: this.$t("Oops..."),
                  text: this.$t("User denied transaction signature."),
                });
              }
              return;
            }
            this.$data.deployedTransaction = transactionHash;
            this.$data.loadingText = this.$t("waiting confirmed...");
            Swal.getTitle().textContent = this.$data.loadingText;
          }
        )
        .on("confirmation", () => {})
        .then((newContractInstance) => {
          this.$data.deployedContract = newContractInstance.options.address;
          Swal.fire({
            icon: "success",
            title: this.$t("Deploy Successed!"),
            html:
              "<b>" +
              this.$t("Transaction Hash") +
              ": </b><p>" +
              this.$data.deployedTransaction +
              "</p>" +
              "<b>" +
              this.$t("Contract Address") +
              ": </b><p>" +
              this.$data.deployedContract +
              "</p>",
            allowOutsideClick: false,
          });
        });
      this.$data.loadingText = this.$t("sending transaction...");
      Swal.fire({
        title: this.$data.loadingText,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      }).then((result) => {
        console.log(result);
      });
    },
    copyTransaction: function () {
      Swal.fire({
        position: "top-end",
        icon: "success",
        text: this.$t("Copy success!"),
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });
    },
    copyContract: function () {
      Swal.fire({
        position: "top-end",
        icon: "success",
        text: this.$t("Copy success!"),
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });
    },
    openExploer: function () {},
  },
  computed: {
    contractDeployable: function () {
      return (
        this.$data.contractTotal != "" &&
        this.$data.contractDecimals != "" &&
        this.$data.contractName.length > 0 &&
        this.$data.contractSymbol.length > 0
      );
    },
    getLoadingText: function () {
      return this.$data.loadingText;
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.field-title {
  margin-bottom: 1.5rem;
}
</style>
